import { useStaticQuery, graphql } from 'gatsby'

export const FAQsData = () => {
  const queryData = useStaticQuery(graphql`
    query FAQsQuery {
      allContentfulFaQs(sort: { order: ASC, fields: title }) {
        nodes {
          description
          title
          createdAt(formatString: "DD/MM/YYYY")
        }
      }
    }
  `)

  return queryData
}

import React from 'react'
import Accordion from '../../components/Accordion/Accordion'
import Footer from '../../components/Footer'
import './faq.scss'
import { FAQsData } from '../../utils/GraphQL/faqQueryData'

const FAQs = () => {
  const {
    allContentfulFaQs: { nodes },
  } = FAQsData()
  const infoCardData = [
    {
      title: 'Market Research',
      content:
        'Deploy surveys against AKTA’s segmented user base to gain insights and answers that meet your research objectives including Opinion polling, A/B testing, Brand awareness, Customer satisfaction, and Purchase feedback.',
    },
    {
      title: 'Industry Research',
      content:
        'Deploy surveys against AKTA’s segmented user base to gain insights and answers that meet your research objectives including Opinion polling, A/B testing, Brand awareness, Customer satisfaction, and Purchase feedback.',
    },
    {
      title: 'A/B Test',
      content:
        'Deploy surveys against AKTA’s segmented user base to gain insights and answers that meet your research objectives including Opinion polling, A/B testing, Brand awareness, Customer satisfaction, and Purchase feedback.',
    },
    {
      title: 'Brand Awareness',
      content:
        'Deploy surveys against AKTA’s segmented user base to gain insights and answers that meet your research objectives including Opinion polling, A/B testing, Brand awareness, Customer satisfaction, and Purchase feedback.',
    },
  ]

  return (
    <div className="faqs-container">
      <div className="col mx-auto w-70 ">
        <h1 className="text-center mt-5">Frequently Asked Questions</h1>
        <div>
          <div className={`col ht-100 w-100 mt-5`}>
            {nodes &&
              nodes.map(({ title, description: content }, index) => (
                <Accordion
                  className={`w-100`}
                  key={index}
                  index={`d` + index}
                  title={title}
                  content={content}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  )
}

export default FAQs
